/* eslint-disable */

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, Route, Routes, useLocation, Navigate, useNavigate } from 'react-router-dom';

import { Grid } from '@mui/material';

import { CalculatorsContext, DraftContext, ErrorContext } from '../../../context';
import { CALC_TYPES, CalculatorsListData, ChartSectionsMappings } from '../../../constants';

// import NotFound from "../../../views/notFound/NotFound";

import LocalCalculator from '../Local/Local';
import CarbonCalculator from '../Carbon/Carbon';
import GlobalCalculator from '../Global/Global';

import Chart from '../../../components/chart/Chart';
import ProgressCircle from '../../../components/progressCircle/ProgressCircle';
import AccordionGroup from '../../../components/accordionGroup/AccordionGroup';
import SelfAssessment from '../../../components/selfAssessment/SelfAssessment';
import SolutionsGlobalModal from '../../../components/solutionsModal/SolutionsGlobalModal.jsx';
import SolutionsModal from '../../../components/solutionsModal/SolutionsModal';
import { ColorScheme } from '../../../constants';

import styles from './CalculatorsRouter.module.scss';

import { getCalcAnswers, getCalcQuestions } from '../../../api/rest/list.js';
import CustomCo2 from '../CustomCo2/CustomCo2.jsx';
import Loading from '../../../components/loading/Loading.jsx';

const initialLoaderFlags = {
  questions: false,
  answers: false,
};

const CalculatorsRouter = () => {
  const navigate = useNavigate();
  /* TODO сделать переход на последний вопрос */
  // const { pathname, state } = useLocation();
  const { pathname } = useLocation();
  const calculatorContainer = useRef();
  const {
    carbonQuestions,
    localQuestions,
    globalQuestions,
    lastVisitedIndex,
    calculatorsList,
    carbonAnswers,
    customCo2Answers,
    calculatorName,
    setChartData,
    chartData,
    section,
    setQuestionsData,
    setAnswersData,
    questionsMap,
    setQuestionsMap,
    config,
  } = useContext(CalculatorsContext);
  const { draft } = useContext(DraftContext);
  const { setErrorAlert } = useContext(ErrorContext);

  const [questionIndex, setQuestionIndex] = useState(0);
  const [questionCount, setQuestionCount] = useState(1);
  // const [chartIndex, setChartIndex] = useState(null);
  const [chartColor, setChartColor] = useState('#12491D');
  const [chartName, setChartName] = useState(null);
  const [chartPartData, setChartPartData] = useState([]);

  const [accordionItems, setAccordionItems] = useState([]);
  const [descriptionItems, setDescriptionItems] = useState([]);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [activeSector, setActiveSector] = useState(null);
  const [loading, setLoading] = useState(0);

  const loadingRef = useRef(null);

  const increaseLoading = (stepName) => {
    if (loadingRef.current) {
      loadingRef.current.increaseLoading(stepName);
    }
  };

  const changeAppLoading = (value = null) => {
    setLoading((value ?? 0) > 0.98 ? 1 : value ?? 0);
  };

  const openModal = (activeItem) => {
    setActiveSector(activeItem);
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setActiveSector(null);
    setIsModalOpened(false);
  };

  useEffect(() => {
    if (!carbonQuestions) return;

    let cumulativeQuestionCount = 0;
    const createCumulativeMaps = () => {
      const scopeMap = [];
      const categoryMap = [];

      carbonQuestions.forEach((scope) => {
        const categoriesInScope = [];
        const scopeStart = cumulativeQuestionCount;
        scope.categories.forEach((category) => {
          const qCount = category.questions.length;
          const start = cumulativeQuestionCount;
          cumulativeQuestionCount += qCount;
          categoriesInScope.push({ start, end: cumulativeQuestionCount - 1 });
        });
        cumulativeQuestionCount += 1; // customCO2 calculator for each scope
        // After processing all categories, store the cumulative count in scopeMap
        scopeMap.push({ start: scopeStart, end: cumulativeQuestionCount - 1 });
        categoryMap.push(categoriesInScope);
      });

      return { scopeMap, categoryMap };
    };
    const { scopeMap, categoryMap } = createCumulativeMaps();
    setQuestionsMap({
      carbon: { scopeMap, categoryMap, start: 0, end: cumulativeQuestionCount - 1 },
    });
    setQuestionCount(cumulativeQuestionCount - 1);
  }, [carbonQuestions]);

  useEffect(() => {}, [localQuestions]);

  useEffect(() => {}, [globalQuestions]);

  useEffect(() => {
    const fetchAnswers = async () => {
      try {
        // Run all requests sequentially and update state safely
        const requests = calculatorsList.map(async (model) => {
          const param = { model, calculator_id: draft.id };
          const res = await getCalcAnswers(param); // Call API
          const { data } = res.data;
          return { [model]: JSON.parse(data?.[model] ?? '{}') }; // Return the result in an object
        });

        // Wait for all requests to complete
        const results = await Promise.all(requests);

        // Merge all results into a single object
        const mergedAnswers = results.reduce((acc, current) => {
          return { ...acc, ...current };
        }, {});

        // Update the context state with the final merged object
        setAnswersData(mergedAnswers);
      } catch (error) {
        setErrorAlert('Failed to load answers:', null, error);
      }
    };

    if (!calculatorsList?.length || !draft) return;
    fetchAnswers().then(() => increaseLoading('answers'));
  }, [calculatorsList, draft]);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        // Run all requests sequentially and update state safely
        const requests = calculatorsList.map(async (model) => {
          const param = { model };
          if (model === 'carbon') param.collection_id = draft.ukGhgCollectionId;
          const res = await getCalcQuestions(param); // Call API
          const { data } = res.data;
          return { ...data }; // Return the result in an object
        });

        // Wait for all requests to complete
        const results = await Promise.all(requests);

        // Merge all results into a single object
        const mergedQuestions = results.reduce((acc, current) => {
          return { ...acc, ...current };
        }, {});

        // Update the context state with the final merged object
        setQuestionsData(mergedQuestions);
      } catch (error) {
        setErrorAlert('Failed to load questions:', null, error);
      }
    };

    if (!calculatorsList?.length || !draft) return;
    fetchQuestions().then(() => increaseLoading('questions'));
  }, [calculatorsList, draft]);

  // init chart data with carbon answers
  useEffect(() => {
    if (!carbonQuestions || !carbonAnswers || !calculatorsList) return;
    const newChartData = [];
    calculatorsList.forEach((model) => {
      const modelConf = config.models.find((m) => m.model === model);
      const { name } = modelConf.texts.en;
      if (model === 'carbon') {
        const carbonChart = carbonQuestions.reduce((acc, scope) => {
          const children = scope.categories.reduce(
            (acc2, category) => [
              ...acc2,
              {
                name: category.name,
                type: 'category',
                color: ColorScheme[scope.code],
                value: category.questions.filter((q) =>
                  carbonAnswers?.[category.code]?.[q.id]?.some((a) => a.filled)
                ).length,
                total: category.questions.length,
                code: category.code,
              },
            ],
            []
          );
          children.push({
            name: `${scope.name}: Custom Co2 Items`,
            type: 'customCO2',
            color: ColorScheme[scope.code],
            value: customCo2Answers?.[scope.code].some((a) => a.filled) ? 1 : 0,
            total: customCo2Answers?.[scope.code].length ?? 1,
            code: scope.code,
          });
          return [
            ...acc,
            {
              name: scope.name,
              calc: name,
              color: ColorScheme[scope.code],
              value: children.filter((c) => c.value > 0).length,
              total: scope.categories.length,
              code: scope.code,
              children,
            },
          ];
        }, []);
        newChartData.push(...carbonChart);
      }
    });
    setChartData(newChartData);
  }, [carbonQuestions, carbonAnswers, calculatorsList]);

  // init accordion data
  useEffect(() => {
    if (!carbonQuestions || !config || !calculatorsList) return;
    const accordion = [];
    calculatorsList
      .filter((model) => model != 'customCO2')
      .forEach((model) => {
        const modelChildren = [];
        const modelConf = config.models.find((m) => m.model === model);
        const { name, info: content } = modelConf.texts.en;
        if (model === 'carbon') {
          const carbonAccordion = carbonQuestions.map((scope) => {
            const children = scope.categories.map((cat) => ({
              expanded: cat.name === activeSector,
              name: cat.name,
              code: cat.name,
              content: cat.description,
              nested: true,
            }));
            children.push({
              expanded: `${scope.name}: Custom Co2 Items` === activeSector,
              name: `${scope.name}: Custom Co2 Items`,
              type: 'customCO2',
              nested: true,
            });
            return {
              expanded:
                scope.name === activeSector ||
                scope.categories.some((cat) => cat.name === activeSector),
              name: scope.name,
              calc: name,
              content: scope.description,
              children: [...children],
            };
          });
          modelChildren.push(...carbonAccordion);
        }
        if (model === 'global') {
          const globalAccordion = globalQuestions.map((dim) => ({
            expanded:
              dim.name === activeSector || dim.categories.some((cat) => cat.name === activeSector),
            name: dim.name,
            calc: name,
            content: dim.description,
            children: dim.categories.map((cat) => ({
              expanded: cat.name === activeSector,
              name: cat.name,
              code: cat.code,
              content: cat.description,
            })),
          }));
          modelChildren.push(...globalAccordion);
        }
        accordion.push({ name, content, expanded: false, children: [...modelChildren] });
      });
    setAccordionItems(accordion);
  }, [carbonQuestions, calculatorsList, config]);

  const onDescriptionSelect = (selected, name) => {
    const items = [...descriptionItems];
    const oldActive = items.findIndex((v) => v.expanded);

    if (oldActive !== -1) items[oldActive].expanded = false;

    if (selected) {
      const newActive = items.findIndex((v) => v.name === name);

      if (newActive !== -1) items[newActive].expanded = true;
    }
    setDescriptionItems(items);
  };

  return (
    <>
      {loading === 1 && (
        <>
          <Grid
            item
            container
            alignItems="center"
            direction="column"
            className={styles.calculatorContainer}
            ref={calculatorContainer}
          >
            <Grid className={styles.breadcrumps}>
              TransparenTerra&nbsp;&nbsp;▷&nbsp;&nbsp;ESG&nbsp;&nbsp;▷&nbsp;&nbsp;
              <Link to="/">Calculator</Link>
              {accordionItems && accordionItems.length && (
                <span>
                  &nbsp;&nbsp;▷&nbsp;&nbsp;
                  {/* <Link to={getFirstQuestionUrl()}>{accordionItems[0].name}</Link> */}
                  {accordionItems[0].name}
                </span>
              )}
            </Grid>
            <SelfAssessment />

            <Grid item container justifyContent="center" className={styles.content}>
              <ProgressCircle current={questionIndex} count={questionCount} color={chartColor} />
              <div className={styles.chartArea}>
                <Chart
                  chartData={chartPartData}
                  hasDesktopFormat={false}
                  onChartClick={() => openModal(chartName)}
                  activeItem={activeSector}
                />
              </div>
              <Grid item container alignItems="center" className={styles.infoArea}>
                <AccordionGroup items={descriptionItems} onAccordionClick={onDescriptionSelect} />
              </Grid>
              <Routes>
                <Route exact path="/carbon" element={<Navigate to="1" />} />
                <Route
                  exact
                  path="/carbon/customCO2/:idx"
                  element={<CustomCo2 setQuestionIndex={setQuestionIndex} />}
                />
                <Route
                  exact
                  path="/carbon/:idx"
                  element={
                    <CarbonCalculator
                      setQuestionIndex={setQuestionIndex}
                      setDescriptionItems={setDescriptionItems}
                      setChartPartData={setChartPartData}
                    />
                  }
                />
                <Route exact path="/local/:idx" element={<LocalCalculator />} />
                <Route
                  exact
                  path="/global/:dimUrl/:catUrl/:idxUrl"
                  element={
                    <GlobalCalculator
                      questionIndex={questionIndex}
                      setQuestionIndex={setQuestionIndex}
                      setAccordionItems={setAccordionItems}
                      calculatorContainerRef={calculatorContainer}
                    />
                  }
                />
                {/* <Route path="*" element={<NotFound />} /> */}
              </Routes>
            </Grid>
          </Grid>
          <SolutionsGlobalModal
            opened={isModalOpened}
            closeModalFunc={closeModal}
            activeItem={activeSector}
            accordionItems={accordionItems}
          />
        </>
      )}
      <Loading
        ref={loadingRef}
        initialLoaderFlags={initialLoaderFlags}
        setLoading={setLoading}
        loading={loading}
      />
    </>
  );
};

export default CalculatorsRouter;
