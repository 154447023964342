import clsx from 'clsx';
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'tt-ui-kit';
import CalcModal from '../../components/CalcModal/CalcModal';
import { DraftContext, CalculatorsContext, ErrorContext } from '../../context/index';
import styles from './Registration.module.scss';
/* eslint-disable-next-line import/no-named-default */
import Form from '../../components/form/Form';
import RegistrationForm from './RegistrationForm';
import RegistrationProject from './RegistrationProject';
import RegistrationCarbon from './RegistrationCarbon';
import { CALC_TYPES } from '../../constants';
import { formatDate } from '../../utils';
import { getCompanyDrafts, getDraftById, updateDraft, replaceDraft } from '../../api/rest/list';

const Registration = () => {
  const [validated, setValidated] = useState(false);
  const [progress, setProgress] = useState(0);
  const [initialDraft, setInitialDraft] = useState(null);
  const [selectedDraft, setSelectedDraft] = useState(null);
  const [draftsList, setDraftsList] = useState([]);
  const [isDisclaimerOpened, setIsDisclaimerOpened] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [isNavigate, setIsNavigate] = useState(false);
  const [company, setCompany] = useState(null);
  const [requiredFields, setRequiredFields] = useState([]);
  const [validity, setValidity] = useState({});

  const [draftIdForRemove, setDraftIdForRemove] = useState(null);
  const [choosedAction, setChoosedAction] = useState(null);
  const [years, setYears] = useState([]);
  const [reportYear, setReportYear] = useState(null);
  const { calculatorsList, calculatorName, setDraftData, config } = useContext(CalculatorsContext);
  const {
    factors,
    setDraftId,
    draftId,
    setDraft,
    companies,
    legalTypes,
    industrialGroups,
    setCompanyId,
  } = useContext(DraftContext);
  const { setErrorAlert } = useContext(ErrorContext);

  const navigate = useNavigate();

  const getFactorId = (year = null) => {
    let factorId = null;
    if (year) {
      const factor = factors.find((f) => f.year === year);
      factorId = factor.id;
    }
    if (!year || !factorId) {
      const maxYear = Math.max(...factors.map((f) => f.year));
      const currentYear = new Date().getFullYear();
      const factor = factors.find(
        (f) => f.year === (maxYear < currentYear ? maxYear : currentYear)
      );
      factorId = factor.id;
    }
    return factorId;
  };

  useEffect(() => {
    if (!draftId) return;
    getDraftById({ id: draftId }).then((res) => {
      const { data: newDraft, success } = res.data;
      if (success) {
        setCompanyId(newDraft.companyId);
        setInitialDraft({ ...newDraft });
        setSelectedDraft({ ...newDraft });
      } else {
        setErrorAlert('Draft was not found', () => navigate('/drafts', { replace: true }));
      }
    });
  }, [draftId]);

  useEffect(() => {
    if (!initialDraft || !config) return;
    if (config.isSingleInYear && initialDraft.periodReportDate)
      setReportYear(initialDraft.periodReportDate.getFullYear());
  }, [initialDraft, config]);

  useEffect(() => {
    if (!selectedDraft || !!selectedDraft.ukGhgCollectionId) return;
    const ghgDraft = {
      ...selectedDraft,
      ukGhgCollectionId: getFactorId(),
    };
    setSelectedDraft(ghgDraft);
  }, [selectedDraft]);

  useEffect(() => {
    if (!initialDraft || !initialDraft.companyId || !initialDraft.type) return;
    getCompanyDrafts({ type: initialDraft.type }, { companyId: initialDraft.companyId }).then(
      (res) => {
        const { data, success } = res.data;
        if (success) {
          setDraftsList(data);
        }
      }
    );
  }, [initialDraft]);

  useEffect(() => {
    if (!companies || !initialDraft || !legalTypes || !industrialGroups) return;
    setCompany(companies[initialDraft.companyId]);
  }, [initialDraft, companies, legalTypes, industrialGroups]);

  useEffect(() => {
    if (!draftsList || !draftsList.length) return;
    const newYears = draftsList.reduce(
      (res, d) => (d.periodReportDate ? res.add(d.periodReportDate.getFullYear()) : res),
      new Set()
    );
    setYears([...newYears]);
  }, [draftsList]);

  useEffect(() => {
    setIsNavigate(false);
  }, []);

  const numberFilter = /^(\d+\.)?\d+$/;

  useEffect(() => {
    if (!calculatorName || !config) return;
    const fields = JSON.parse(config.requiredFields);
    setRequiredFields({
      ...fields,
      reportYear: config.isSingleInYear, //  ? 1 : 0,
    });
  }, [calculatorName, config]);

  useEffect(() => {
    if (!requiredFields) return;
    setValidity({
      value: 0,
      total: Object.keys(requiredFields).reduce(
        (sum, key) =>
          key === 'reportYear' ? sum + requiredFields.reportYear : sum + requiredFields[key].length,
        0
      ),
    });
  }, [requiredFields]);

  const validatedFields = () => {
    if (!selectedDraft) return 0;

    let totalValidated = 0;
    totalValidated += requiredFields.numberFields
      ? requiredFields.numberFields.filter((n) => numberFilter.test(selectedDraft[n])).length
      : 0;
    totalValidated += requiredFields.stringFields
      ? requiredFields.stringFields.filter((n) => !!selectedDraft[n]).length
      : 0;
    totalValidated += requiredFields.reportYear && reportYear ? 1 : 0;
    totalValidated += requiredFields.dateFields
      ? requiredFields.dateFields.filter((n) => !Number.isNaN(Date.parse(selectedDraft[n]))).length
      : 0;
    return totalValidated;
  };

  useEffect(() => {
    if (!selectedDraft || !config) return;
    setValidity((value) => ({
      value: validatedFields(),
      total: value.total,
    }));
  }, [selectedDraft, reportYear, config]);

  useEffect(() => {
    /* For the “Project” calculator, you must fill in both dates or neither */
    const newCheck =
      validity.value === validity.total &&
      (calculatorName !== CALC_TYPES.PROJECT ||
        (calculatorName === CALC_TYPES.PROJECT &&
          !!selectedDraft &&
          !!selectedDraft.periodReportDate === !!selectedDraft.periodReportDateEnd));
    setValidated(newCheck);

    const progressValue = validity.value / validity.total;
    setProgress(
      Number.isNaN(progressValue) || progressValue === 0 ? '1px' : `${progressValue * 100}%`
    );
  }, [validity]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setSelectedDraft((newDraft) => ({
      ...newDraft,
      [name]: value,
    }));
  };

  const onChangeDate = (e) => {
    const newValue = e?.target?.value;
    setDraftIdForRemove(null);
    setReportYear(newValue ? newValue.getFullYear() : null);
  };

  const warningModalOpen = () => {
    setIsWarningModalOpen(true);
  };

  useEffect(() => {
    if (!reportYear) return;
    const exist = (draftsList || []).find(
      (d) => d.periodReportDate && d.periodReportDate.getFullYear() === reportYear
    );
    if (exist && initialDraft.id === exist.id) return;
    if (exist) {
      warningModalOpen();
      setSelectedDraft({ ...exist });
    } else {
      setSelectedDraft({ ...selectedDraft, periodReportDate: new Date(reportYear, 0, 1) });
    }
  }, [reportYear]);

  const goToNextPage = () => {
    setDraftId(selectedDraft.id);
    setDraft({ ...selectedDraft });
    setIsNavigate(true);
  };

  useEffect(() => {
    if (!choosedAction) return;
    if (choosedAction === 'load') {
      goToNextPage();
    }
    if (choosedAction === 'new') {
      setDraftIdForRemove(selectedDraft.id);
    }
    setIsWarningModalOpen(false);
    setChoosedAction(null);
  }, [choosedAction]);

  const resetChoosing = () => {
    setChoosedAction(null);
    setReportYear(null);
    setSelectedDraft({
      ...initialDraft,
      annualTurnover: selectedDraft.annualTurnover,
      netProfit: selectedDraft.netProfit,
      yearTaxes: selectedDraft.yearTaxes,
      numberUniqueProducts: selectedDraft.numberUniqueProducts,
      annualProductivityEachPosition: selectedDraft.annualProductivityEachPosition,
      employeesNumber: selectedDraft.employeesNumber,
      name: selectedDraft.name,
      periodReportDate: new Date(reportYear, 0, 1),
      uk_ghg_collection_id: selectedDraft.ukGhgCollectionId,
    });
    setIsWarningModalOpen(false);
  };

  const saveDraft = async () => {
    const input = {
      id: selectedDraft.id,
      annual_turnover: `${selectedDraft.annualTurnover ?? 0}`,
      net_profit: `${selectedDraft.netProfit ?? 0}`,
      year_taxes: `${selectedDraft.yearTaxes ?? 0}`,
      number_unique_products: `${selectedDraft.numberUniqueProducts ?? 0}`,
      annual_productivity_each_position: `${selectedDraft.annualProductivityEachPosition ?? 0}`,
      employees_number: `${selectedDraft.employeesNumber ?? 0}`,
      period_report_date:
        selectedDraft.type === CALC_TYPES.PROJECT
          ? formatDate(selectedDraft.periodReportDate)
          : formatDate(selectedDraft.periodReportDate.getFullYear()),
      period_report_date_end: formatDate(selectedDraft.periodReportDateEnd),
      type: selectedDraft.type,
      uk_ghg_collection_id: selectedDraft.ukGhgCollectionId,
    };

    if (draftIdForRemove) {
      await replaceDraft({ ...input }, { companyId: selectedDraft.companyId });
    } else {
      await updateDraft(
        {
          ...input,
          name: selectedDraft.name ?? '',
        },
        { companyId: selectedDraft.companyId }
      );
    }
  };

  const startNavigation = async (url) => {
    await saveDraft();
    navigate(url);
  };

  useEffect(() => {
    if (!isNavigate) return;
    const url = selectedDraft.lastPosition
      ? selectedDraft.lastPosition
      : `/calculators/${calculatorsList[0]}`;
    startNavigation(url);
  }, [isNavigate]);

  return (
    <>
      {initialDraft && selectedDraft && (
        <Modal
          open={isWarningModalOpen}
          onClose={() => setIsWarningModalOpen(false)}
          title="Start new assessment"
          closeOnlyByControls
        >
          <div className={styles.text}>
            {`You are about to start a new "${initialDraft.name}" assessment.
            Please note that all data from the last saved draft will be lost,
            and the "${selectedDraft.name}" will disappear from the list of all drafts.`}
          </div>
          <div className={clsx(styles.buttonBlock, styles.alertBB)}>
            <Button type="default" onClick={() => resetChoosing()}>
              Cancel
            </Button>
            <Button type="primary" onClick={() => setChoosedAction('load')}>
              Load previous
            </Button>
            <Button type="primary" onClick={() => setChoosedAction('new')}>
              Replace with new
            </Button>
          </div>
        </Modal>
      )}
      <CalcModal
        opened={isDisclaimerOpened}
        closeModalFunc={() => setIsDisclaimerOpened(false)}
        headerContent="Financial data"
        okBtnText="OK"
      >
        All this data is mandatory. Please fill out required fields.
      </CalcModal>
      <div className={styles.profileContainer}>
        <div className={styles.profile}>
          <div
            className={styles.progressBar}
            style={{
              background: `linear-gradient(to right, #01A0C6 ${progress}, transparent ${progress}, transparent 100%)`,
            }}
          />
          {selectedDraft && (
            <Form headerContent="General Information">
              {company && (
                <div className={styles.infoBlock}>
                  <div>
                    <div className={styles.info}>Company name</div>
                    <div className={styles.text}>{company.companyName}</div>
                  </div>
                  <div>
                    <div className={styles.info}>Address</div>
                    <div className={styles.text}>{company.address1}</div>
                  </div>
                  <div>
                    <div className={styles.info}>Company legal type</div>
                    <div className={styles.text}>{legalTypes[company.legalTypeId].name}</div>
                  </div>
                  <div>
                    <div className={styles.info}>Industrial sector</div>
                    <div className={styles.text}>
                      {industrialGroups[company.industrialGroupId].name}
                    </div>
                  </div>
                  <div>
                    <div className={styles.info}>Established date</div>
                    <div className={styles.text}>{company.establishedDate ?? 'no date'}</div>
                  </div>
                </div>
              )}
              {(calculatorName === CALC_TYPES.LOCAL || calculatorName === CALC_TYPES.GLOBAL) && (
                <RegistrationForm
                  onChange={onChange}
                  onChangeDate={onChangeDate}
                  openDisclaimer={() => setIsDisclaimerOpened(true)}
                  isDisabled={!draftId}
                  numberFilter={numberFilter}
                  data={selectedDraft}
                  years={years}
                  reportYear={reportYear}
                  dateDisable={!!initialDraft.periodReportDate}
                  factorList={factors}
                />
              )}
              {calculatorName === CALC_TYPES.CARBON && (
                <RegistrationCarbon
                  onChange={onChange}
                  onChangeDate={onChangeDate}
                  openDisclaimer={() => setIsDisclaimerOpened(true)}
                  isDisabled={!draftId}
                  numberFilter={numberFilter}
                  data={selectedDraft}
                  years={years}
                  reportYear={reportYear}
                  dateDisable={!!initialDraft.periodReportDate}
                  factorList={factors}
                />
              )}
              {calculatorName === CALC_TYPES.PROJECT && (
                <RegistrationProject
                  onChange={onChange}
                  openDisclaimer={() => setIsDisclaimerOpened(true)}
                  isDisabled={!draftId}
                  numberFilter={numberFilter}
                  data={selectedDraft}
                  dateDisable={!!initialDraft.periodReportDate}
                  factorList={factors}
                />
              )}
            </Form>
          )}
          {/* <PartialLoader loading={!draft} isLightContainer /> */}
          <div className={clsx(styles.navigation, styles.single)}>
            <Button variant="contained" onClick={goToNextPage} disabled={!validated}>
              NEXT
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registration;
