/* eslint-disable */

import PropTypes from 'prop-types';
import React, { useReducer } from 'react';

import AppContext from './appContext';
import appReducer from './appReducer';

const AppState = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, {});

  const setItemInStorage = (name, object) => {
    window.sessionStorage.setItem(name, JSON.stringify(object));
  };

  const getItemFromStorage = (name) => JSON.parse(window.sessionStorage.getItem(name));

  const removeItemFromStorage = (name) => {
    window.sessionStorage.removeItem(name);
  };

  const clearStorage = () => {
    window.sessionStorage.clear();
  };

  return (
    <AppContext.Provider
      value={{
        setItemInStorage,
        getItemFromStorage,
        removeItemFromStorage,
        clearStorage,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppState.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppState;
