import clsx from 'clsx';
import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { ReactComponent as LoadingLogo } from '../../assets/icons/loadingLogo.svg';
import styles from './Loading.module.scss';

// Компонент Loading с инкапсулированной логикой загрузки
const Loading = forwardRef(({ initialLoaderFlags, loading, setLoading }, ref) => {
  const [loadingFlags, setLoadingFlags] = useState(initialLoaderFlags);

  // Метод для обновления флага загрузки
  const increaseLoading = (stepName) => {
    setLoadingFlags((prevFlags) => ({
      ...prevFlags,
      [stepName]: true,
    }));
  };

  // Расчет процента загрузки на основе флагов
  useEffect(() => {
    const keys = Object.keys(loadingFlags);
    const completedSteps = keys.reduce((acc, key) => acc + (loadingFlags[key] ? 1 : 0), 0);
    const newLoadingValue = completedSteps / keys.length;
    setLoading(newLoadingValue > 0.98 ? 1 : newLoadingValue);
  }, [loadingFlags]);

  // Передаем наружу методы через ref
  useImperativeHandle(ref, () => ({
    increaseLoading,
    getLoading() {
      return loading;
    },
    resetLoading() {
      setLoadingFlags(initialLoaderFlags);
      setLoading(0);
    },
  }));

  return (
    <>
      {loading != null && loading !== 1 && (
        <div
          id="loadingsScreen"
          className={clsx(styles.container, loading === 1 ? styles.stopped : '')}
        >
          <span className={styles.percents}>{Math.round(loading * 100)}%</span>
          <LoadingLogo className={styles.logo} />
          <span className={styles.loading}>Loading...</span>
        </div>
      )}
    </>
  );
});

export default Loading;
