/* eslint-disable */
import clsx from 'clsx';
import PropTypes, { string } from 'prop-types';
import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Grid, Button, Tooltip } from '@mui/material';
import { CalculatorsContext, DraftContext, ErrorContext } from '../../../context';

import Form from '../../../components/form/Form';
import Pagination from '../../../components/pagination/Pagination';

import styles from './index.module.scss';
import CalculationCost from '../../../components/calculationCost/CalculationCost';
import PreliminaryResults from '../Carbon/components/PreliminaryResults';
import { ReactComponent as HelpSvg } from '../../../assets/icons/help.svg';
import CustomCo2Item from './components/CustomCo2Item';
import QuestionSharing from '../../../components/questionSharing/QuestionSharing';

const tooltipText = `Please add the components used in this project.
For each component, specify the amount you need.
Select supplier for a component from the list or add them manually. Invite the supplier to the platform to confirm their participation in your project. This will provide greater transparency for your project.

Carbon Footprint (Optional):
If you have information about a component's carbon footprint (environmental impact), you can provide it here.

If you're unsure about any details, feel free to invite colleagues or teammates to collaborate by clicking "Invite to answer."
`;

const CustomCo2 = ({}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { idx } = useParams();

  const [scopeIndex, setScopeIndex] = useState(null);
  const [preliminaryModalOpened, setPreliminaryModalOpened] = useState(false);

  const { draftId, draft } = useContext(DraftContext);
  const { config, customCO2Answers, chartData, setAnswersData, isAnyAnswerExists, questionsMap } =
    useContext(CalculatorsContext);

  const activities = [...(customCO2Answers?.[scopeIndex]?.[id] ?? [])];

  const setAllQuestionModalOpened = () => {};

  useEffect(() => {
    setScopeIndex(idx - 1);
  }, []);

  const changeActivity = () => {};
  const openDeleteModal = () => {};
  const getItemsList = () => {};

  const onBackClick = () => {
    const index = questionsMap?.carbon?.scopeMap[scopeIndex]?.end;
    navigate(`/calculators/carbon/${index}`);
  };

  const onNextClick = () => {
    setPreliminaryModalOpened(true);
  };

  const goForward = () => {
    const index = questionsMap?.carbon?.scopeMap[scopeIndex]?.end;
    if (index == questionsMap?.carbon?.end) {
      navigate(`/buy_credits/${draftId}`);
    } else navigate(`/calculators/carbon/${index + 2}`);
  };

  const closePreliminaryModal = () => {
    setPreliminaryModalOpened(false);
    goForward();
  };

  return (
    <>
      {preliminaryModalOpened && (
        <PreliminaryResults
          opened={preliminaryModalOpened}
          closeModalFunc={closePreliminaryModal}
          draftId={draftId}
          scopeIndex={scopeIndex}
        />
      )}
      <div className={styles.formContainer}>
        <div>
          <Form
            headerContent={
              <>
                <div className={clsx(styles.headerContainer, styles.companyHeaderContainer)}>
                  Items/Components
                  <span>
                    <Tooltip tooltipText={tooltipText} enterTouchDelay={0}>
                      <HelpSvg className={clsx(styles.infoIcon, styles.topIcon)} />
                    </Tooltip>
                  </span>
                </div>
                <div>
                  <QuestionSharing
                    calculatorId={draftId}
                    categoryCode={scopeIndex}
                    questionId={scopeIndex}
                  />
                </div>
              </>
            }
          >
            {activities &&
              activities.map((activity, indx) => (
                <CustomCo2Item
                  key={indx}
                  activity={activity}
                  index={indx}
                  onChange={changeActivity}
                  onRemove={openDeleteModal}
                  getItemsList={getItemsList}
                  isLast={indx === activities.length - 1}
                />
              ))}
          </Form>
          <CalculationCost />
        </div>
        <div className={styles.navigation}>
          <Button variant="outlined" onClick={onBackClick}>
            BACK
          </Button>
          <Button variant="outlined" onClick={() => setAllQuestionModalOpened(true)}>
            view all questions
          </Button>
          {questionsMap?.carbon && scopeIndex != null && (
            <Pagination
              activePage={questionsMap?.carbon?.scopeMap[scopeIndex]?.end + 1}
              pagesCount={questionsMap?.carbon?.scopeMap[scopeIndex]?.end + 1}
            />
          )}

          <Button variant="contained" onClick={onNextClick}>
            {questionsMap?.carbon?.scopeMap[scopeIndex]?.end == questionsMap?.carbon?.end
              ? 'FINISH'
              : 'NEXT'}
          </Button>
        </div>
      </div>
    </>
  );
};

CustomCo2.propTypes = {
  // scope: PropTypes.string,
  // setQuestionIndex: PropTypes.func,
};

CustomCo2.defaultProps = {
  // setQuestionIndex: () => {},
};

export default CustomCo2;
