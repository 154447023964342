/* eslint-disable */
import clsx from 'clsx';
import PropTypes, { string } from 'prop-types';
import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Grid, Button, Tooltip } from '@mui/material';
import { CalculatorsContext, DraftContext, ErrorContext } from '../../../context';

import Form from '../../../components/form/Form';
import Pagination from '../../../components/pagination/Pagination';

import AddButton from './AddButton';
import Component6Fields from './components/Component6Fields';

import { ReactComponent as HelpSvg } from '../../../assets/icons/help.svg';
import styles from './index.module.scss';
import CalcModal from '../../../components/CalcModal/CalcModal';
import FileUploader from '../../../components/fileUploader/FileUploader';
import CalculationCost from '../../../components/calculationCost/CalculationCost';
import { updateCalcAnswer } from '../../../api/rest/list';
import AllQuestionsModal from './components/AllQuestionsModal';

import QuestionSharingWrapper from '../../../components/questionSharingWrapper/QuestionSharingWrapper';

const Carbon = ({ setQuestionIndex, setDescriptionItems, setChartPartData }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { idx } = useParams();

  const [previousScopeIndex, setPreviousScopeIndex] = useState(null);

  const { setErrorAlert } = useContext(ErrorContext);
  const { draftId, draft } = useContext(DraftContext);
  const {
    config,
    carbonAnswers,
    carbonQuestions,
    chartData,
    setAnswersData,
    isAnyAnswerExists,
    questionsMap,
  } = useContext(CalculatorsContext);

  const [isFileModalOpened, setIsFileModalOpened] = useState(false);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [index, setIndex] = useState(0);
  const [scopeIndex, setScopeIndex] = useState(null);
  const [categoryIndex, setCategoryIndex] = useState(null);

  const [categoryCode, setCategoryCode] = useState(null);
  const [question, setQuestion] = useState({});
  const [allQuestionModalOpened, setAllQuestionModalOpened] = useState(false);

  const { id, fields } = question;
  const activities = [...(carbonAnswers?.[categoryCode]?.[id] ?? [])];

  // returns items for first selections which are not alternative
  const getSingleChoise = (item = question, i = 0) => {
    const { singleFields = [] } = question;
    if (singleFields.length == i) return {};
    const newItem = item[singleFields[i]][0];
    const nextItem = getSingleChoise(newItem, i + 1);
    return { [singleFields[i]]: newItem.id, ...nextItem };
  };

  // initialize new activity with null values by the available fields of the question
  const initNewActivity = () => ({
    ...fields.reduce((obj, item) => ({ ...obj, [item]: null }), {}),
    ...getSingleChoise(),
  });

  // set index by url
  useEffect(() => {
    setIndex(idx - 1 < 0 ? 0 : idx - 1);
    // setQuestionIndex(idx - 1 < 0 ? 0 : idx - 1);
  }, [idx]);

  const findScope = (questionIndex) => {
    if (questionIndex < 0) return null;
    const scopeMap = questionsMap?.carbon?.scopeMap ?? [];
    for (let i = 0; i < scopeMap.length; i++) {
      if (questionIndex <= scopeMap[i].end) {
        return { indx: i, ...scopeMap[i] }; // Return the scope index
      }
    }
    return { indx: null, start: null, end: null }; // Question out of range
  };

  // Function to find the category index within a given scope
  const findCategory = (scopeIndex, questionIndex) => {
    const categoryMap = questionsMap?.carbon?.categoryMap ?? [];
    const categories = categoryMap[scopeIndex]; // Get categories for the scope
    for (let i = 0; i < categories.length; i++) {
      if (questionIndex <= categories[i].end) {
        return { indx: i, ...categories[i] }; // Return the category index
      }
    }
    return { indx: null, start: null, end: null }; // Question out of range for categories in the scope
  };

  useEffect(() => {
    if (!config || !carbonQuestions || scopeIndex == null || categoryIndex == null) return;
    const accordion = [];
    const model = config.models.find((m) => m.model === 'carbon');
    const { name, info: content } = model.texts.en;
    accordion.push({ name, content, expanded: false });
    const scope = carbonQuestions[scopeIndex];
    accordion.push({ name: scope.name, content: scope.description, expanded: false });
    const category = carbonQuestions[scopeIndex].categories[categoryIndex];
    if (category.description)
      accordion.push({ name: category.name, content: category.description, expanded: false });
    setDescriptionItems(accordion);
  }, [config, scopeIndex, categoryIndex, carbonQuestions]);

  useEffect(() => {
    if (!questionsMap?.carbon || !carbonQuestions) return;
    const { indx: newScopeIndex } = findScope(index);
    setScopeIndex(newScopeIndex);

    const { indx: newCategoryIndex, end } = findCategory(newScopeIndex, index);
    setCategoryIndex(newCategoryIndex);

    const newCategory = carbonQuestions[newScopeIndex].categories[newCategoryIndex];
    setCategoryCode(newCategory.code);

    const newQuestionIndex = index - (end - newCategory.questions.length) - 1;
    const newQuestion = newCategory.questions[newQuestionIndex];
    setQuestion(newQuestion);
    //calculator arc
    setQuestionIndex(index);
  }, [questionsMap, carbonQuestions, index]);

  useEffect(() => {
    //chartData
    if (!chartData?.length || scopeIndex == null || categoryIndex == null) return;
    const questionChartData = chartData[scopeIndex].children[categoryIndex];
    setChartPartData([questionChartData]);
  }, [chartData, scopeIndex, categoryIndex]);

  const changeCarbonAnswers = (activities) => {
    const { code } = carbonQuestions[scopeIndex].categories[categoryIndex];
    setAnswersData({
      carbon: {
        ...carbonAnswers,
        [code]: {
          ...(carbonAnswers?.[code] ?? {}),
          [id]: activities?.length ? activities : [initNewActivity()],
        },
      },
    });
  };

  // init new result activity if it is not exist
  useEffect(() => {
    if (!id || !fields || !categoryCode || !!carbonAnswers?.[categoryCode]?.[id]) return;
    changeCarbonAnswers();
  }, [carbonAnswers, question, categoryCode]);

  // get the number of filled activities
  const activityFilledCount = () => {
    if (!question || !activities) return -1;

    return activities.filter((act) => fields.reduce((res, k) => res && !!act[k], true)).length;
  };

  useEffect(() => {
    setPreviousScopeIndex(scopeIndex);
    if (!previousScopeIndex || scopeIndex === 0 || scopeIndex <= (previousScopeIndex ?? 0)) return;
    setPreliminaryModalOpened(true);
  }, [scopeIndex]);

  // save data to the draft with filtering empty fields
  const onSaveAnswer = async () => {
    const answers = Object.keys(carbonAnswers).reduce((res, code) => {
      const categoryAnswer = Object.keys(carbonAnswers[code]).reduce((result, key) => {
        const components = (carbonAnswers[code][key] ?? []).filter((component) => component.filled);
        return components.length ? { ...result, [key]: components } : result;
      }, {});
      return categoryAnswer ? { ...res, [code]: categoryAnswer } : res;
    }, {});

    try {
      await updateCalcAnswer({
        calculator_id: draftId,
        model: 'carbon',
        data: JSON.stringify(answers),
        last_position: pathname,
        state_graphics: JSON.stringify(chartData),
      });
    } catch (_) {
      // TODO: показывать ошибку, если запрос не прошёл
      // см. комментарий в Local.jsx
    }
  };

  // get index of the current calculator in the calculatorsList array
  // const getCalcIndex = () =>
  //   calculatorsList.findIndex((calculator) => calculator.name === `scope${scope}`);

  // get the list of items by the field
  const getItemsList = (activity, field, currentQuestion) => {
    if (!currentQuestion) return [];
    const { singleFields = [], exceptFields = {}, fields: currentFields } = currentQuestion ?? {};

    if (!currentFields || !currentFields.length) return [];
    // get the deep of the tree to the current field
    const sliced = currentFields.slice(0, currentFields.indexOf(field) + 1);
    // get the list of items by the field
    const itemsRes = sliced.reduce((items, f) => {
      if (f === field || items?.length === 0) {
        return items;
      }
      const newItem = exceptFields[f]
        ? items[f][0]
        : items[f].find((el) => el.id === activity[f]) ?? [];
      return newItem;
    }, currentQuestion);
    return itemsRes[field] ?? [];
  };

  const getLabel = (field) => {
    switch (field) {
      case 'f1':
        return 'Activity';
      case 'f2':
        return 'Type';
      case 'f3':
        return 'Size';
      case 'f4':
        return 'Additional';
      case 'f5':
        return 'Additional';
      default:
        return '';
    }
  };

  const getItemNames = (activity, currentQuestion) => {
    const { fields: currentFields } = currentQuestion;
    if (!currentFields || !currentFields.length) return {};

    return currentFields.reduce((res, field) => {
      const items = getItemsList(activity, field, currentQuestion);
      const item = items.find((el) => el.id === activity[field]);
      return { ...res, [field]: item?.name ?? '' };
    }, {});
  };

  const onBackClick = async () => {
    await onSaveAnswer();
    const { indx: newScopeIndex, end: scopeEnd } = findScope(index - 1);
    const { indx: newCategoryIndex, categoryEnd } = findCategory(newScopeIndex, index - 1);
    if (newScopeIndex != null && categoryEnd == null && scopeEnd === index - 1) {
      navigate(`/calculators/carbon/customCO2/${newScopeIndex + 1}`);
    } else if (newScopeIndex == null) {
      navigate('/registration');
    } else navigate(`/calculators/carbon/${index}`);
  };

  const onNextClick = async () => {
    await onSaveAnswer();
    const { indx: newScopeIndex, end: scopeEnd } = findScope(index + 1);
    const { indx: newCategoryIndex, categoryEnd } = findCategory(newScopeIndex, index + 1);
    if (newScopeIndex != null && categoryEnd == null && scopeEnd === index + 1) {
      navigate(`/calculators/carbon/customCO2/${newScopeIndex + 1}`);
    } else if (newScopeIndex == null) {
      //index >= questionsMap?.carbon?.scopeMap[questionsMap?.carbon?.scopeMap.length - 1].end
      // TODO need to rewrite
      if (isAnyAnswerExists()) {
        navigate(`/buy_credits/${draftId}`);
      } else navigate('/drafts');
    } else navigate(`/calculators/carbon/${index + 2}`);
  };

  // add new activity
  const addActivity = () => {
    const newActivities = [...activities, { ...initNewActivity() }];
    changeCarbonAnswers(newActivities);

    setTimeout(() => {
      const activitiesElements = document.getElementsByClassName('activity');
      activitiesElements[activitiesElements.length - 1].scrollIntoView({
        behavior: 'smooth',
      });
    });
  };

  // change activity data
  const changeActivity = (actIndex, field, value) => {
    // singleFields - fields that have only one value
    // exceptFields - fields that have alternative tree's deep
    const { singleFields = [], exceptFields = {} } = question;
    // key-value pairs {id: field} of the fields that should be disabled
    const newActivities = [...activities];
    const activity = newActivities[actIndex];

    // the fields of the current activity are divided into two parts by the current field
    const lowLevelFields = fields.includes(field) ? fields.slice(fields.indexOf(field)) : [];
    const upLevelFields = fields.includes(field) ? fields.slice(0, fields.indexOf(field)) : fields;

    // the data of the current activity is cleared from the current field to the end
    const clearActivity = lowLevelFields.reduce(
      (obj, item) => ({ ...obj, [item]: null, filled: false }),
      { ...activity }
    );
    const newActivity = { ...clearActivity, [field]: value };

    // check if the upper level fields of the current activity are filled
    const isLevelUpFilled = upLevelFields.reduce((res, f) => res && !!activity[f], true);

    // if the upper level fields of the current activity aren't filled...
    if (!isLevelUpFilled) {
      // ...check if the current field is single-length
      const isLevelUpSingle = upLevelFields.reduce((res, f) => res && singleFields[f], true);
      // if the current field is single-length...
      if (isLevelUpSingle) {
        // ...get the id of the single-length field
        upLevelFields.forEach((f) => {
          const { id: singlId } = getItemsList(newActivity, f, question)[0];
          newActivity[f] = singlId;
        });
      }
    }

    // get the id and name of the disabled field
    const disabledField = !!exceptFields[newActivity.f1 ?? 0]?.[0];
    // get the fields that should be filled to validate the data except the disabled field
    const fieldsForValid = disabledField ? fields.slice(0, fields.indexOf(disabledField)) : fields;
    // check if the data is filled
    const selectFilled = fieldsForValid.reduce((res, f) => res && !!newActivity[f], true);
    const isDataFilled = selectFilled && !!newActivity.data;

    // get the factor_id of the current activity by the last field that should be filled
    const factorId = newActivity[fieldsForValid[fieldsForValid.length - 1]] ?? null;
    newActivities[actIndex] = {
      ...newActivity,
      filled: isDataFilled,
      factorId: factorId ?? null,
    };
    changeCarbonAnswers(newActivities);
  };

  const removeActivity = () => {
    const newActivities = activities.filter((el, indx) => indx !== deleteIndex);
    changeCarbonAnswers(newActivities);
  };

  // check if the new activity is allowed for disable the button "Add Activity"
  const isNewActivityAllowed = () => {
    if (!question || !activities.length) return false;
    return activities[activities.length - 1]?.filled;
  };

  const closeFileModal = () => {
    setIsFileModalOpened(false);
  };

  const openDeleteModal = (indx) => {
    setDeleteIndex(indx);
    setIsDeleteModalOpened(true);
  };

  const closeDeleteModal = () => {
    setDeleteIndex(null);
    setIsDeleteModalOpened(false);
  };

  return (
    <>
      {fields && carbonAnswers && (
        <AllQuestionsModal
          opened={allQuestionModalOpened}
          closeModalFunc={() => setAllQuestionModalOpened(false)}
          questionId={id}
          carbonQuestions={carbonQuestions}
          answers={carbonAnswers}
          getItemNames={getItemNames}
          getLabel={getLabel}
        />
      )}
      <CalcModal
        opened={isDeleteModalOpened}
        closeModalFunc={closeDeleteModal}
        headerContent="Delete component"
        okBtnText="Delete"
        okFunc={removeActivity}
        needAgreement={false}
        backBtnText="Cancel"
      >
        Do you really want to delete activity №{deleteIndex + 1}?
      </CalcModal>
      <CalcModal
        opened={isFileModalOpened}
        closeModalFunc={closeFileModal}
        headerContent="Upload your confirmation docs"
        okBtnText="Ok"
        needAgreement={false}
        backBtnText="Back"
        backFunc={closeFileModal}
      >
        {question?.id && <FileUploader questionCode={question.id} model="carbon" />}
      </CalcModal>
      <Grid
        item
        container
        wrap="nowrap"
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        className={styles.formContainer}
      >
        {question?.id && (
          <div>
            <Form
              headerContent={
                <>
                  <div className={clsx(styles.headerContainer, styles.companyHeaderContainer)}>
                    {`${question?.name?.substring(0, question?.name?.lastIndexOf(' '))} `}
                    <span>
                      {question?.name?.substring(question.name.lastIndexOf(' ') + 1)}
                      <div className={styles.icons}>
                        {question?.name?.startsWith('WTT') && (
                          <Tooltip title="Well-to-tank" enterTouchDelay={0}>
                            <HelpSvg className={clsx(styles.infoIcon, styles.topIcon)} />
                          </Tooltip>
                        )}
                      </div>
                    </span>
                  </div>
                  <div>
                    <QuestionSharingWrapper
                      categoryCode={categoryCode}
                      questionId={id}
                      model={'carbon'}
                    />
                  </div>
                </>
              }
            >
              {activities &&
                activities.map((activity, indx) => (
                  <Component6Fields
                    key={indx}
                    question={question}
                    activity={activity}
                    index={indx}
                    onChange={changeActivity}
                    onRemove={openDeleteModal}
                    getItemsList={getItemsList}
                    isLast={indx === activities.length - 1}
                    getLabel={getLabel}
                  />
                ))}
              <div className={styles.docButtonCont}>
                {question?.id && <FileUploader questionCode={question.id} model="carbon" />}
                <AddButton
                  className={styles.addButton}
                  text="Add Activity"
                  onClick={addActivity}
                  isDisabled={!isNewActivityAllowed()}
                />
              </div>
            </Form>
            <CalculationCost />
          </div>
        )}
        <div className={styles.navigation}>
          <Button className={styles.back} variant="outlined" onClick={onBackClick}>
            BACK
          </Button>
          <Button
            className={styles.allQuestionBtn}
            variant="outlined"
            onClick={() => setAllQuestionModalOpened(true)}
          >
            view all questions
          </Button>
          {questionsMap?.carbon && scopeIndex != null && (
            <div className={styles.paginator}>
              <Pagination
                activePage={index + 1 - questionsMap?.carbon?.scopeMap[scopeIndex].start}
                pagesCount={
                  questionsMap?.carbon?.scopeMap[scopeIndex].end -
                  questionsMap?.carbon?.scopeMap[scopeIndex].start +
                  1
                }
              />
            </div>
          )}

          <Button className={styles.next} variant="contained" onClick={onNextClick}>
            {index >= questionsMap?.carbon?.scopeMap[questionsMap?.carbon?.scopeMap.length - 1].end
              ? 'FINISH'
              : 'NEXT'}
          </Button>
        </div>
      </Grid>
    </>
  );
};

Carbon.propTypes = {
  // scope: PropTypes.string,
  // setQuestionIndex: PropTypes.func,
};

Carbon.defaultProps = {
  // setQuestionIndex: () => {},
};

export default Carbon;
