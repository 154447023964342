import React, { useContext, useEffect, useMemo, useState } from 'react';
import QuestionSharing from '../questionSharing/QuestionSharing';

import { CalculatorsContext, DraftContext } from '../../context';
import { addShare, getShare } from '../../api/rest/list';

const QuestionSharingWrapper = (props) => {
  const { categoryCode, questionId, model } = props;

  const { carbonQuestions } = useContext(CalculatorsContext);
  const { draftId, user } = useContext(DraftContext);
  const [treeData, setTreeData] = useState(null);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const questionTree = useMemo(
    () =>
      carbonQuestions.reduce((sNodes, scope) => {
        const scopeNode = {
          title: scope.name,
          key: scope.code,
          children: scope.categories.reduce((cNodes, category) => {
            const categoryNode = {
              title: category.name,
              key: category.code,
              children: category.questions.map((question) => ({
                title: question.name,
                key: `${category.code}|${question.id}`,
                id: question.id,
                catCode: category.code,
              })),
            };
            cNodes.push(categoryNode);
            return cNodes;
          }, []),
        };
        sNodes.push(scopeNode);
        return sNodes;
      }, []),
    [carbonQuestions]
  );

  useEffect(() => setTreeData(questionTree ?? []), [questionTree]);

  useEffect(() => {
    if (!treeData || !categoryCode || !questionId) return;
    const selectedKey = `${categoryCode}|${questionId}`;
    const selected = treeData.reduce((acc, scope) => {
      const filtered = scope.children.filter((category) => category.key === categoryCode);
      if (filtered.length) acc.push(selectedKey, filtered[0].key, scope.key);
      return acc;
    }, []);
    setSelectedKeys([...selected]);
  }, [treeData, categoryCode, questionId]);

  const onResultCallback = ({ addressee, data }) => {
    if (!addressee || !data) return;
    const { categoryCode: catCode, questionId: qId } = data;
    const payload = {
      ...addressee,
      calculator_id: draftId,
      calculator_type: model,
      receivers: [{ id: user.id, type: 'USER' }],
      questions: [
        ...data.map((el) => ({
          outer_id: el.id,
          type: model,
          data: JSON.stringify({ category_code: catCode }),
        })),
      ],
    };
    addShare(payload).then((res) => {
      console.log('res', res);
    });
  };

  useEffect(() => {
    getShare({ calculator_id: draftId }).then((res) => console.log('res', res));
  }, []);

  return (
    <QuestionSharing
      treeData={treeData}
      selectedInit={selectedKeys}
      resultCallback={onResultCallback}
    />
  );
};

export default QuestionSharingWrapper;

// export const getShare = async (params, headers) =>
//   axiosClient.get(API_SHARE_GET_URL, axiosSSOConfigPrivate(headers, { params: params }));

// export const addShare = async (params, headers) =>
//   axiosClient.post(API_SHARE_ADD_URL, params, axiosSSOConfigPrivate(headers));

// export const deleteShare = async (params, headers) =>
//   axiosClient.post(API_SHARE_DELETE_URL, params, axiosSSOConfigPrivate(headers));
