/* eslint-disable */

import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { Select } from 'tt-ui-lib/core';
import UiTextField from '../../../../components/uiTextField/UiTextField';
import MuiCheckBox from '../../../../components/muiCheckBox/MuiCheckBox';
import { ReactComponent as DeleteSvg } from '../../../../assets/icons/delete.svg';

import { NumberFilter } from '../../../../constants';
import styles from '../index.module.scss';

const Component6Fields = ({
  question,
  activity,
  index,
  onChange,
  onRemove,
  getItemsList,
  isLast,
  getLabel,
}) => {
  const { singleFields = [], exceptFields = {}, fields } = question;
  const handleChange = (e) => {
    const { name, value } = e.target;
    onChange(index, name, value ?? null);
  };

  const handleCheckBoxChange = (e) => {
    onChange(index, e.target.name, !activity.isNotAvailable);
  };

  const isDisabled = (field) =>
    (fields.indexOf(field) > 0 &&
      fields.indexOf(field) > fields.indexOf(singleFields.findLast((el) => el)) &&
      activity[fields[fields.indexOf(field) - 1]] == null) ||
    exceptFields[activity.f1]?.[0] === field;

  const getStyle = () => {
    const { length } = fields.filter((field) => singleFields.indexOf(field) < 0);
    switch (length) {
      case 0:
        return styles.col1;
      case 1:
      case 3:
        return styles.col2;
      case 2:
      case 4:
      case 5:
        return styles.col3;
      default:
        return styles.col2;
    }
  };

  const getOptions = (field) =>
    getItemsList(activity, field, question)?.map((item) => {
      const newToolTip = item.toolTip ?? {};
      // field === 'f3' ? { tooltipText: 'Tooltip caption 1...', tooltipTrigger: 'click' } : {};
      return {
        value: item?.factorId ?? item?.id ?? '',
        label: item?.name ?? '',
        ...newToolTip,
      };
    });

  return (
    <>
      <div key={index} className={clsx([styles.componentInputs, getStyle(), 'activity'])}>
        <div className={styles.componentLabel}>
          Activity №{index + 1}
          <span>{index !== 0 && <DeleteSvg onClick={() => onRemove(index)} />}</span>
        </div>
        {fields
          .filter((field) => singleFields.indexOf(field) < 0)
          .map((field) => (
            <Select
              key={field}
              label={getLabel(field)}
              name={field}
              value={activity[field] ?? {}}
              options={getOptions(field)}
              className={styles.componentInput}
              onChange={(e) => handleChange({ target: { value: e, name: field } })}
              disabled={isDisabled(field)}
              allowClear
            />
          ))}
        <UiTextField
          className={styles.componentInput}
          name="data"
          label="Enter your data"
          value={activity.data}
          onChange={handleChange}
          filter={NumberFilter}
          inputProps={{ maxLength: 20 }}
        />
      </div>
      <div
        className={clsx(
          styles.isNotAvailable,
          isLast && !activity.isNotAvailable ? styles.partWidth : styles.fullWidth,
          activity.isNotAvailable && styles.selected
        )}
      >
        <MuiCheckBox
          label="This information is not available"
          className={styles.checkbox}
          value={activity.isNotAvailable}
          onChange={handleCheckBoxChange}
          name="isNotAvailable"
        />
        {activity.isNotAvailable && (
          <UiTextField
            className={styles.info}
            name="info"
            label="Information"
            value={activity.info}
            onChange={handleChange}
            required={false}
            inputProps={{ maxLength: 255 }}
          />
        )}
      </div>
    </>
  );
};

Component6Fields.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  question: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  activity: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  getItemsList: PropTypes.func.isRequired,
  isLast: PropTypes.bool,
  getLabel: PropTypes.func,
};

Component6Fields.defaultProps = {
  question: {},
  activity: {},
  index: 0,
  onChange: () => {},
  onRemove: () => {},
  isLast: false,
};

export default Component6Fields;
