import { axiosClient, axiosSSOConfigPrivate } from '../../rest-client';
import { API_SHARE_GET_URL, API_SHARE_ADD_URL, API_SHARE_DELETE_URL } from './urls';

export const getShare = async (params, headers) =>
  axiosClient.get(API_SHARE_GET_URL, axiosSSOConfigPrivate(headers, { params: params }));

export const addShare = async (params, headers) =>
  axiosClient.post(API_SHARE_ADD_URL, params, axiosSSOConfigPrivate(headers));

export const deleteShare = async (params, headers) =>
  axiosClient.post(API_SHARE_DELETE_URL, params, axiosSSOConfigPrivate(headers));
