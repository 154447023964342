import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import PhoneInput from 'react-phone-input-2';
import { PartialLoader } from 'tt-ui-kit';
import styles from './InviteCalcUser.module.scss';
import { ReactComponent as GrayPlusIco } from '../../assets/icons/gray-plus.svg';
import { parseErrors } from '../../utils';
import {
  openNotification,
  Input,
  InputTextArea,
  Modal,
  Button,
  Select,
  ANTD_COMPONENTS,
} from 'tt-ui-lib/core';
import { ReactComponent as SendIcon } from '../../assets/icons/sendWhite.svg';

const { Form, Row, Col } = ANTD_COMPONENTS;

const aboutText = `You're about to invite your colleague/partner to the TransparenTerra Platform. TransparenTerra will send this request on your behalf to join TransparenTerra with a "Individual" role. Once the invitation is accepted and your colleague/partner completes the sign-up, you will receive a notification.`;

const whitespacesReg = /^\s*\S.*$/;
const specialCharReg = /^[^<>'"/;`%]*$/;
const numberReg = /^([^0-9]*)$/;

export const InviteCalcUser = (props) => {
  const {
    title = '',
    onCloseCallback = () => {},
    roleQuery,
    sendInviteQuery,
    setInvitations = false,
    disabled,
    btnType,
    btnText,
  } = props;
  const [roleData, setRoleData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [phoneInputLabel, setPhoneInputLabel] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  const [getRoles] = useLazyQuery(roleQuery);
  const [sendModal] = useMutation(sendInviteQuery, {
    onError: (graphQLErrors) => {
      const errorsList = parseErrors({ errors: graphQLErrors });
      if (errorsList.email) {
        form.setFields([
          {
            name: 'email',
            errors: ['The email has already been taken.'],
          },
        ]);
      }
    },
  });

  const onClose = () => {
    setIsModalOpen(false);
  };

  const onOpen = () => {
    setIsModalOpen(true);
  };

  const getIndividualRoleId = () => {
    if (roleData) {
      const individualRole = roleData.find((role) => role.name === 'individual');
      return individualRole ? individualRole.id : undefined;
    }
    return undefined;
  };

  const getRolesFromApi = async () => {
    const roleBase = await getRoles();
    if (roleBase?.data) {
      setRoleData(roleBase?.data?.roles || []);
    }
  };

  useEffect(() => {
    getRolesFromApi();
  }, []);

  useEffect(() => {
    if (roleData) {
      const individualRoleId = getIndividualRoleId();
      if (individualRoleId) {
        form.setFieldsValue({ role_id: individualRoleId });
      }
    }
  }, [roleData]);

  const submit = async (formData) => {
    setLoader(true);
    try {
      const { data } = await sendModal({ variables: { input: { ...formData, type: 0 } } });

      if (data?.sendInvitation?.invitation) {
        if (setInvitations) {
          setInvitations((current) => [data.sendInvitation.invitation, ...current.slice(0, -1)]);
        }
        openNotification({
          message: 'Your invitation has been sent successfully',
          type: 'success',
        });
        form.resetFields();
        onClose();
      }
      setLoader(false);
      onCloseCallback();
    } catch (error) {
      const errorsList = parseErrors({ errors: error });
      if (errorsList.email) {
        form.setFields([
          {
            name: 'email',
            errors: ['The email has already been taken.'],
          },
        ]);
      } else {
        openNotification({
          message: 'Something went wrong, try again',
          type: 'error',
        });
      }
      setLoader(false);
    }
  };

  const renderSelectValue = (val) => {
    const roles =
      roleData?.map((element) => ({
        label: element.name.charAt(0).toUpperCase() + element.name.slice(1),
        value: element.id,
      })) || [];
    const roleItem = roles.find((item) => item.value === val);
    return roleItem?.label || null;
  };

  return (
    <>
      <Button
        type={btnType ?? 'link'}
        onClick={onOpen}
        disabled={disabled}
        className={styles.docButton}
      >
        <GrayPlusIco />
        {btnText ?? 'Invite colleague'}
      </Button>
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          onClose={() => onClose()}
          onClick={(e) => e.stopPropagation()}
          title={<div style={{ paddingRight: '40px' }}>{title}</div>}
          className={styles.modal}
        >
          <Form
            form={form}
            onFinish={submit}
            className={styles.modalForm}
            initialValues={{
              type: 0,
              first_name: '',
              last_name: '',
              email: '',
              tel: '',
              role_id: '',
              comment: '',
              note: '',
              max_count: 1,
            }}
          >
            <div className={styles.modalContent}>
              <Row gutter={12}>
                <Col className={styles.modalAbout} span={24}>
                  {aboutText}
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="first_name"
                    rules={[
                      { required: true, message: 'The first name field must not be empty.' },
                      { max: 255, message: 'Too many characters.' },
                      {
                        pattern: whitespacesReg,
                        message: 'The field cannot contain only spaces.',
                      },
                      {
                        pattern: specialCharReg,
                        message: 'Special characters are prohibited.',
                      },
                      {
                        pattern: numberReg,
                        message: 'Numbers are prohibited.',
                      },
                    ]}
                  >
                    <Input label="First Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="last_name"
                    rules={[
                      { max: 255, message: 'Too many characters.' },
                      {
                        pattern: whitespacesReg,
                        message: 'The field cannot contain only spaces.',
                      },
                      {
                        pattern: specialCharReg,
                        message: 'Special characters are prohibited.',
                      },
                      {
                        pattern: numberReg,
                        message: 'Numbers are prohibited.',
                      },
                    ]}
                  >
                    <Input label="Last Name" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: 'Please enter your email address.' },
                      {
                        type: 'email',
                        message: 'Please enter a valid email address, e.g. joe@mail.com.',
                      },
                    ]}
                  >
                    <Input label="Email" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="tel"
                    rules={[
                      {
                        min: 9,
                        message: 'Check phone number correctness',
                      },
                    ]}
                    getValueFromEvent={(value) => value}
                  >
                    <PhoneInput
                      specialLabel={phoneInputLabel}
                      buttonClass={styles.phone}
                      inputClass={styles.phoneInput}
                      onChange={(value) => form.setFieldsValue({ tel: value })}
                      onFocus={() => setPhoneInputLabel('Phone number')}
                      onBlur={() => setPhoneInputLabel('')}
                      placeholder="Phone number"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="role_id"
                    rules={[{ required: true, message: 'This is a required field.' }]}
                  >
                    <Select
                      label="Suggest a role"
                      style={{ width: '100%' }}
                      options={
                        roleData?.map((element) => ({
                          label: element.name.charAt(0).toUpperCase() + element.name.slice(1),
                          value: element.id,
                        })) || []
                      }
                      renderValue={renderSelectValue}
                      size="medium"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={24}>
                  <Form.Item name="comment">
                    <InputTextArea
                      style={{ padding: '10px' }}
                      inputMode="text"
                      placeholder="Comment for the invitee"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={24}>
                  <Form.Item name="note">
                    <InputTextArea style={{ padding: '10px' }} placeholder="Note for me" />
                  </Form.Item>
                </Col>
              </Row>
              <div className={styles.buttonWrapper}>
                <Button type="default" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  className={styles.modal_buttonSend}
                  disabled={loader}
                  type="primary"
                  htmlType="submit"
                >
                  <SendIcon className={styles.sendIcon} />
                  Send
                </Button>
              </div>
            </div>
          </Form>

          {loader && (
            <div>
              <PartialLoader />
            </div>
          )}
        </Modal>
      )}
    </>
  );
};
