import React, { useEffect, useState, useCallback } from 'react';
import clsx from 'clsx';
import { Button, Input, Modal, InputTextArea, ANTD_COMPONENTS } from 'tt-ui-lib/core';
import { PartialLoader } from 'tt-ui-kit';
import { ReactComponent as GrayPlusIco } from '../../assets/icons/gray-plus.svg';
import styles from './InviteToAnswer.module.scss';
import { InviteCalcUser } from '../inviteCalcUser/InviteCalcUser';
import UserBlockSimple from '../userBlockSimple/UserBlockSimple';
import { GET_INVITATIONS_WITH_PAGINATION } from '../../api/apollo/api';
import { useLazyQuery, useQuery } from '@apollo/client';

const { Radio, Row, Col } = ANTD_COMPONENTS;

const { TextArea } = Input;

const tooltipText = `info`;
const aboutInvite = `You're about to invite your colleague/partner to the TransparenTerra Platform.
  TransparenTerra will send this request on your behalf to join TransparenTerra with a "Individual" role.
  Once the invitation is accepted and your colleague/partner completes the sign-up, you will receive a notification.`;

const InviteToAnswer = (props) => {
  const {
    disabled,
    btnType,
    btnText,
    title,
    onCloseCallback = () => {},
    roleQuery,
    sendInviteQuery,
  } = props;

  const [invitations, setInvitations] = useState([]);
  const [users, setUsers] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [selectedId, setSelectedUserId] = useState(null);
  const [message, setMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [timeoutID, setTimeoutID] = useState(null);

  const [queryFilter, setQueryFilter] = useState({
    page: 1,
    first: 10,
    input: {
      ordersBy: {
        column: 'created_at',
        direction: 'DESC',
      },
    },
  });

  const { loading, data, fetchMore, refetch } = useQuery(GET_INVITATIONS_WITH_PAGINATION, {
    variables: queryFilter,
    fetchPolicy: 'network-only',
  });

  const setPage = (page) => {
    setQueryFilter((current) => ({
      ...current,
      page,
    }));
  };

  useEffect(() => {
    if (data) {
      setInvitations((prevUsers) => [...prevUsers, ...data.invitationsWithPagination.data]);
      setHasMore(data.invitationsWithPagination.paginatorInfo.hasMorePages);
    }
  }, [data]);

  const loadMoreUsers = useCallback(() => {
    if (hasMore && !loading) {
      fetchMore({
        variables: { page: queryFilter.page + 1 },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prevResult;
          setPage(queryFilter.page + 1);
          return {
            invitationsWithPagination: {
              ...fetchMoreResult.invitationsWithPagination,
              data: [
                ...prevResult.invitationsWithPagination.data,
                ...fetchMoreResult.invitationsWithPagination.data,
              ],
            },
          };
        },
      }).finally(() => setLoader(false));
    }
  }, [hasMore, loading, fetchMore]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setInputValue(value);
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
    const timeout = setTimeout(() => {
      setQueryFilter((current) => ({
        ...current,
        page: 1,
        input: { ...current.input, partialName: value },
      }));
      setPage(1);
      setInvitations([]);
      refetch(); // Перезапрашиваем данные с новыми фильтрами
    }, 500);
    setTimeoutID(timeout);
  };

  // Очищаем состояние и перезапрашиваем данные при открытии
  const onOpen = () => {
    setIsModalOpen(true);
    // setPage(1);
    // setInvitations([]);
    // setHasMore(true);

    // setQueryFilter({
    //   page: 1,
    //   first: 10,
    //   input: {
    //     ...queryFilter.input,
    //     partialName: inputValue,
    //   },
    // });

    // refetch({
    //   page: 1,
    //   first: 10,
    //   input: {
    //     ...queryFilter.input,
    //     partialName: inputValue,
    //   },
    // });
  };

  const onClose = () => {
    setIsModalOpen(false);
    setSelectedUserId(false);
  };

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollHeight - scrollTop === clientHeight) {
      loadMoreUsers();
      setLoader(true);
    }
  };

  const onNextStep = () => {
    onClose();
    if (onCloseCallback)
      onCloseCallback({
        message,
        invitation_id: invitations.find((el) => el.id === selectedId)?.id,
        user_id: users.find((el) => el.id === selectedId)?.id,
      });
  };

  return (
    <>
      <Button
        type={btnType ?? 'link'}
        onClick={onOpen}
        disabled={disabled}
        className={styles.docButton}
      >
        <GrayPlusIco />
        {btnText ?? 'Invite to answer'}
      </Button>
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          onClose={() => onClose(false)}
          onClick={(e) => e.stopPropagation()}
          title={<div style={{ paddingRight: '50px' }}>{title ?? 'Invite to answer'}</div>}
          className={styles.modal}
          closeOnlyByControls
        >
          <div className={styles.modalContent}>
            <div className={styles.modalAbout}>{aboutInvite}</div>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={12}>
                <Input
                  placeholder="Поиск пользователя"
                  value={inputValue}
                  onChange={handleSearchChange}
                />
              </Col>
              <Col xs={24} sm={24} md={12} className={styles.modal_inviteButton}>
                <InviteCalcUser
                  title="Invite your colleagues/partners to contribute to this question"
                  onClose={() => setIsModalOpen(false)}
                  roleQuery={roleQuery}
                  sendInviteQuery={sendInviteQuery}
                  setInvitations={() => {}}
                  onCloseCallback={onOpen}
                />
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                {invitations.length ? (
                  <div className={styles.radioWrapper} onScroll={handleScroll}>
                    <Radio.Group
                      onChange={(e) => setSelectedUserId(e.target.value)}
                      value={selectedId}
                      style={{ width: '100%', border: 'none' }}
                    >
                      {invitations.map((invitation) => (
                        <Radio
                          key={invitation.id}
                          value={invitation.id}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '8px 0',
                          }}
                        >
                          <UserBlockSimple user={invitation} />
                        </Radio>
                      ))}
                    </Radio.Group>
                  </div>
                ) : (
                  <div style={{ padding: '10px 0' }}>No users found</div>
                )}
                {loading && <div>Loading...</div>}
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                <InputTextArea
                  style={{ padding: '10px' }}
                  inputMode="text"
                  placeholder="Message"
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                />
              </Col>
            </Row>
            <div className={styles.buttonWrapper}>
              <Button className={styles.modal_button} type="default" onClick={onClose}>
                Cancel
              </Button>
              <Button
                className={styles.modal_button}
                disabled={!selectedId}
                type="primary"
                onClick={onNextStep}
              >
                Request a pesponse
              </Button>
            </div>
          </div>
          {loader && (
            <div>
              <PartialLoader />
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default InviteToAnswer;
